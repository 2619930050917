import Link from 'next/link'
import { ArticleSpecificType } from '../lib/article'
import { Button } from '@mui/material'
import Image from 'next/image'

export const Article = ({ body, img, link, title }: ArticleSpecificType) => {
  return (
    <div className='bg-gray-50 col-span-1 grid shadow-[0_1px_3px_rgba(0,0,0,0.2)] hover:shadow-[0_3px_6px_rgba(0,0,0,0.3)] transition-shadow duration-300 ease-in-out h-[454px] max-xl:h-auto max-992:w-auto [&>*:not(:first-child)]:px-2 [&>*:not(:first-child)]:pb-2 '>
      <Link href={link ?? '#'} className='overflow-hidden transition-all duration-300 h-56'>
        {img ? (
          <Image
            width={400}
            height={400}
            src={img}
            alt={title ?? ''}
            className='brightness-[.85] hover:brightness-100 transition-all duration-500 ease-in-out object-cover size-full'
          />
        ) : (
          <div className='size-full bg-gray-200 animate-pulse' />
        )}
      </Link>
      <h2 className='font-semibold text-[16px] transition-colors duration-300 ease-in-out hover:text-black mt-2 max-xl:h-[62px] max-xl:overflow-hidden max-md:max-h-[51px] max-xl:px-2 max-xl:p-0 max-xl:-h-[52px]'>
        <Link href={link ?? '/'}>{title}</Link>
      </h2>
      <p className='text-[14px] text-[#666] font-normal leading-8 max-xl:overflow-hidden max-xl:h-[90px] max-md:max-h-[90px] max-xl:p-0 max-xl:px-2'>
        {body}
      </p>
      <div className='w-full flex justify-end items-end transition-all duration-300 ease-in-out'>
        <Link href={link ?? ''}>
          <Button
            sx={{
              fontSize: '14px !important',
              marginBottom: '8px',
              marginLeft: '12px',
              fontWeight: '400',
              border: '1px #b20b51 solid',
              width: '100px',
              height: '35px',
              backgroundColor: 'white',
              borderRadius: '3px',
              boxShadow: '2px 2px 4px rgba(0,0,0,0.2)',
              ':hover': {
                background: '#b20b51',
                color: 'white',
              },
            }}
          >
            ادامه مطلب
          </Button>
        </Link>
      </div>
    </div>
  )
}

export const ArticleSkeleton = () => {
  return (
    <div className='bg-gray-50 animate-pulse col-span-1 h-[475px] max-xl:h-auto max-992:w-auto'>
      <div className='h-full max-xl:min-h-[145px] flex flex-col [&>*]:mt-4 [&>*:first-child]:mt-0'>
        <div className='bg-gray-200 w-full h-48 animate-pulse' />
        <div className='px-2 flex-1 flex flex-col justify-between pb-4'>
          <div className='bg-gray-200 w-full h-10 animate-pulse' />
          <div className='w-full flex flex-col mt-4'>
            <div className='bg-gray-200 w-full h-5 animate-pulse' />
            <div className='bg-gray-200 w-full h-10 animate-pulse' />
            <div className='bg-gray-200 w-8/12 h-5 animate-pulse' />
          </div>
          <div className='w-full flex justify-end'>
            <div className='bg-gray-200 w-20 h-10 animate-pulse'></div>
          </div>
        </div>
      </div>
    </div>
  )
}
