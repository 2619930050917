import { Maybe, SelectedConfigurableOption } from '@graphcommerce/graphql-mesh'
import Image from 'next/image'
import { useState, useEffect } from 'react'
import { cn } from '../../lib/utils'

type ShowSwatchColorsOnPLPType = {
  configurable_options: Array<Maybe<SelectedConfigurableOption>>
  className?: {
    wrapper?: string
  }
}

function ShowSwatchColorsOnPLP({ configurable_options, className }: ShowSwatchColorsOnPLPType) {
  const baseServerUrl = process.env.NEXT_PUBLIC_BACKEND_URL
  const [count, setCount] = useState<string[]>([])
  useEffect(() => {
    const newCount: string[] = []
    if (configurable_options?.length) {
      configurable_options?.forEach((option) => {
        option?.values?.forEach((item) => {
          if (item?.swatch_data && item?.swatch_data?.__typename === 'ImageSwatchData') {
            newCount.push(item?.swatch_data?.value as string)
          }
        })
      })
    }
    setCount(newCount)
  }, [configurable_options])

  return count.length ? (
    <div className={cn('w-full flex flex-row gap-2 justify-center', className?.wrapper)}>
      {count.map((item, index) => {
        if (index < 4)
          return (
            <Image
              key={item}
              src={baseServerUrl + 'media/attribute/swatch' + item}
              width={20}
              height={20}
              alt=''
              className='rounded-[50%]'
            />
          )
        if (index === 5)
          return (
            <p key={item} className='w-[20px] h-[20px] border border-[#ddd] rounded-full'>{`${
              count.length - 4
            }+`}</p>
          )
        return null
      })}
    </div>
  ) : null
}

// eslint-disable-next-line import/no-default-export
export default ShowSwatchColorsOnPLP
