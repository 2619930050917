import { Money } from '@graphcommerce/magento-store'
import { Trans } from '@lingui/react'
import { Hidden } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import { ProductWidgetsType } from '../../../../api/landingPage/widgets'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import SalesSlider from './SalesSlider'

type DiscountSelectionType = {
  mostDiscountedWidget: ProductWidgetsType[]
}

// eslint-disable-next-line import/no-default-export
export default function DiscountSelection(props: DiscountSelectionType) {
  const { mostDiscountedWidget } = props
  return (
    <>
      <div className='flex justify-between items-center max-md:items-end pt-8 mb-3.5 text-primary-500 max-md:flex-end max-md:pt-0 max-md:mt-3'>
        <div className='flex items-center max-md:flow max-md:flex-col max-md:items-start'>
          {/* <Hidden mdDown> */}
          <AccessAlarmIcon sx={{ width: '45px', height: '45px' }} className='max-md:mb-2' />
          {/* </Hidden> */}
          <Link href='/c/discount/q/category_uid/MzY2/sort/saving'>
            <h4 className='text-xl font-semibold'>
              <Trans id='Select discounts' />
            </h4>
          </Link>
        </div>
        <Link
          href='/c/discount/q/category_uid/MzY2/sort/saving'
          className='text-[13px] font-semibold px-2'
        >
          + <Trans id='view all' />
        </Link>
      </div>
      <div className='flex-1 grid grid-cols-1 md:grid-cols-2 gap-2.5 max-992:flex max-md:grid max-md:gap-1'>
        <div className='grid grid-cols-2 gap-1 992:gap-2.5 order-2 md:order-1 max-992:w-[416px] max-md:w-full'>
          {mostDiscountedWidget?.slice(0, 4)?.map((product) => (
            <div
              key={product.id}
              className='bg-white relative shadow-sm hover:shadow-md transition-shadow border border-solid border-light-50 flex flex-col items-center p-2'
            >
              <Link
                href={`/p/${product?.url_key}`}
                className='max-md:w-[21rem] max-md:h-[21rem] contents'
              >
                <Image
                  width={150}
                  height={150}
                  className='max-md:w-80 max-md:h-auto'
                  src={product?.image?.url}
                  alt=''
                />
              </Link>

              <Link
                href={`/p/${product.url_key}`}
                className='text-sm text-center text-default-900 transition-colors hover:text-primary-500'
              >
                {product.name}
              </Link>

              <div className='w-full flex justify-between items-center mt-3 max-md:flex-col max-md:mt-5 max-xl:mt-0 max-xl:flex-col'>
                <span className='text-sm text-light-400 line-through'>
                  {product?.price_range?.minimum_price?.discount?.percent_off > 0 && (
                    <Money {...product?.price_range?.minimum_price?.regular_price} />
                  )}
                </span>
                <span className='text-sm text-secondary-300 font-semibold'>
                  <Money {...product?.price_range?.minimum_price?.final_price} />
                </span>
              </div>
              {product?.price_range?.minimum_price?.discount?.percent_off > 0 && (
                <span className='w-9 h-9 bg-error-500 absolute left-2 top-2 text-center text-sm text-white flex items-center justify-center rounded-full rounded-br-none max-md:w-[30px] max-md:h-[30px]'>
                  %{Math.round(product?.price_range?.minimum_price?.discount?.percent_off)}
                </span>
              )}
            </div>
          ))}
        </div>

        <div className='grid grid-cols-1 grid-rows-1 order-1 md:order-2'>
          <SalesSlider
            mostDiscountedWidget={mostDiscountedWidget?.slice(4, mostDiscountedWidget?.length)}
          />
        </div>
      </div>
    </>
  )
}
