import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useLoading } from '../../context/loadingContext'
import useLogin from '../../components/AuthForms/useLogin'
function useRedirectToAdmin() {
  const { handleLogin } = useLogin()
  const { query } = useRouter()
  const router = useRouter()
  const { setLoading } = useLoading()

  const loginUserByToken = async (token: string) => {
    setLoading(true)
    handleLogin(token, null, false, true,true)
      .catch((e) => {
        alert(e.message)
        router.push('/')
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (query.token) {
      loginUserByToken(query.token as string)
    }
  }, [query.token])
}

export default useRedirectToAdmin
